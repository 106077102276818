import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/vendor/paid', data)
}
//添加
export function getAdd(data) {
    return post('psi/vendor/paidAdd', data)
}
//设置状态
export function getDel(data) {
    return post('psi/vendor/delPaid', data)
}



